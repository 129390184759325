import * as React from "react"
import Footer from "../../components/Footer"
import Layout from "../../components/Layout"
import NavBar from "../../components/NavBar"
import ContactForm from "../../components/pages/contact/ContactForm"
import SEO from "../../components/Seo"
import { IPageProps } from "../../typings/gatsbyPage"

const Distributeurs: React.FunctionComponent<IPageProps> = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="Contact Distributeurs"
        description="Formulaire de contact pour les distributeurs, restaurateurs et marques"
        keywords={["contact", "distributeur", "restaurateur", "marque"]}
      />
      <NavBar activePage="contact" />
      <ContactForm contactType="distributeur" locationHash={location.hash} />
      <Footer />
    </Layout>
  )
}

export default Distributeurs
