import * as React from "react"
import "./ContactForm.scss"
type ContactType = "entreprise" | "particulier" | "distributeur"
interface ContactFormProps {
  contactType: ContactType
  locationHash: string
}

const ContactForm: React.FunctionComponent<ContactFormProps> = ({
  contactType,
  locationHash,
}) => {
  return (
    <section className="contact padding background--electric-blue">
      <h1 className="heading heading--1 heading--svg heading--svg-underline">
        Contactez-nous
      </h1>
      <h2 className="heading heading--2 heading--off-white">
        {contactType === "entreprise"
          ? "Entreprises"
          : contactType === "particulier"
          ? "Particuliers"
          : "Distributeurs"}
      </h2>
      <p className="paragraph paragraph--header paragraph--off-white welcome">
        {welcome(contactType)}
      </p>
      <form
        name={getFormName(contactType, locationHash)}
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input
          type="hidden"
          name="form-name"
          value={getFormName(contactType, locationHash)}
        />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </p>
        {contactType === "distributeur" && (
          <>
            <label
              htmlFor="distributeur_type"
              className="heading heading--2 small heading--off-white"
            >
              Vous êtes
            </label>
            <ul>
              <li>
                <input
                  type="radio"
                  id="distributeur"
                  name="distributeur_type"
                  value="distributeur"
                />
                <label
                  htmlFor="distributeur"
                  className="paragraph paragraph--off-white"
                >
                  Un distributeur
                </label>
              </li>
            </ul>
            <ul>
              <li>
                <input
                  type="radio"
                  id="restaurateur"
                  name="distributeur_type"
                  value="restaurateur"
                />
                <label
                  className="paragraph paragraph--off-white"
                  htmlFor="restaurateur"
                >
                  Un restaurateur
                </label>
              </li>
            </ul>
            <ul>
              <li>
                <input
                  type="radio"
                  id="marque"
                  name="distributeur_type"
                  value="marque"
                />
                <label
                  className="paragraph paragraph--off-white"
                  htmlFor="marque"
                >
                  Une marque
                </label>
              </li>
            </ul>
          </>
        )}
        {contactType !== "particulier" && (
          <>
            <label
              htmlFor="name"
              className="heading heading--2 small heading--off-white"
            >
              {name(contactType)}{" "}
              <abbr title="required" aria-label="required">
                *
              </abbr>
            </label>
            <input type="text" name="name" id="name" />
          </>
        )}

        <label
          htmlFor="email"
          className="heading heading--2 small heading--off-white"
        >
          Email{" "}
          <abbr title="required" aria-label="required">
            *
          </abbr>
        </label>
        <input type="email" name="email" id="email" />
        <label
          htmlFor="message"
          className="heading heading--2 small heading--off-white"
        >
          Votre message{" "}
          <abbr title="required" aria-label="required">
            *
          </abbr>
        </label>
        <textarea name="message" id="message" rows={5} />
        <button className="link button button--color-pink" type="submit">
          Envoyer
        </button>
      </form>
    </section>
  )
}

const welcome = (contactType: ContactType): string => {
  switch (contactType) {
    case "entreprise":
      return `Merci pour votre intérêt. L'équipe La Consigne GreenGo reviendra vers vous très vite pour vous proposer l'offre la plus adaptée`
    case "particulier":
      return `Merci pour votre intérêt! Laissez-nous vos coordonnées et nous vous recontacterons dans les plus brefs délais`
    case "distributeur":
      return `Merci pour votre intérêt. L'équipe La Consigne GreenGo reviendra vers vous très vite pour vous proposer l'offre la plus adaptée`
  }
}

const name = (contactType: Exclude<ContactType, "particulier">): string => {
  switch (contactType) {
    case "entreprise":
      return "Nom de votre entreprise"
    case "distributeur":
      return "Nom de votre enseigne ou marque"
  }
}

export default ContactForm

const getFormName = (contactType: ContactType, locationHash: string) =>
  locationHash !== undefined && locationHash !== ""
    ? "support"
    : `${contactType} contact`
